import { useCallback, useEffect, useState } from 'react';

import localforage from 'localforage';

type Setter = (value: any) => Promise<any>;

export function useLocalforage<T extends Array<any>>(keys: string[], options: LocalForageOptions = {}) {
  const channelId = `localforage-${options.storeName ?? 'default'}-${options.name ?? 'default'}`;
  const [store, setStore] = useState<LocalForage | null>(null);
  const [values, setValues] = useState<T>([] as unknown as T);
  const [setters, setSetters] = useState<Setter[]>([] as Setter[]);
  const refresh = useCallback(async () => {
    if (store) {
      const values = await Promise.all(keys.map((key) => store.getItem(key)));
      const setters = keys.map((key) => (value: any) => store.setItem(key, value));

      setValues(values as T);
      setSetters(setters);

      return values;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys.join('/'), !!store]);
  const setItem = useCallback(
    async (key: string, value: any) => {
      if (store) {
        const result = await store.setItem(key, value);

        const channel = getChannel(channelId, 'send');

        channel.postMessage({ key, value });

        return result;
      }
    },
    [channelId, store]
  );

  useEffect(() => (refresh(), undefined), [refresh]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (!store && setStore(localforage.createInstance(options)), undefined), [options]);

  useEffect(() => {
    const channel = getChannel(channelId, 'receive');

    function listener({ data }: any) {
      if (keys.includes(data.key)) {
        const { key, value } = data;

        setValues((values) => {
          const index = keys.indexOf(key);

          if (index > -1) {
            values[index] = value;
          }

          return structuredClone(values) as T;
        });
      }
    }

    channel.addEventListener('message', listener);

    return () => {
      channel.removeEventListener('message', listener);
      /**
       * Leave channel open, or figure out a way to handle it as a singleton.
       */
      // closeChannel(channelId, 'send');
      // closeChannel(channelId, 'receive');
    };
  }, [setValues, keys, channelId]);

  return { values, refresh, setters, setItem, store };
}

const CHANNELS = new Map<string, BroadcastChannel>();

function getChannel(channelId: string, sendOrReceive: 'send' | 'receive') {
  const id = `${channelId}-${sendOrReceive}`;

  if (!CHANNELS.has(id)) {
    CHANNELS.set(id, new BroadcastChannel(channelId));
  }

  return CHANNELS.get(id) as BroadcastChannel;
}

function closeChannel(channelId: string, sendOrReceive: 'send' | 'receive') {
  const id = `${channelId}-${sendOrReceive}`;
  const channel = CHANNELS.get(id);

  if (channel) {
    CHANNELS.delete(id);
    channel.close();
  }
}
