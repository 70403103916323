'use client';

import { QuizState, answerSchema } from '../../providers/schemas';
import React, { useCallback, useEffect, useMemo } from 'react';
import { parseQuizState, quizInitialState } from './quiz-schema';

import { Box } from 'ui/components/box';
import { Button } from 'ui/components/button';
import { Form } from 'ui/components/form';
import { Icon } from '../../../components/icon';
import { Typography } from 'ui/components/typography';
import { css } from '../../../../styled-system/css';
import quizQuestions from './quiz.json';
import { useLocalforage } from 'ui/hooks/use-localforage';
import { useRouter } from 'next/navigation';
import { useIsDevUser } from 'ui/components/dev';

export const LOCALFORAGE_KEY_QUIZZES = 'quizzes';
export const LOCALFORAGE_KEY_QUIZ_ACTIVE = 'quiz-active';
export const SUBMIT_BUTTON_ID = 'quiz-submit';
const REQUIRED_POINTS = 5;
const REQUIRED_ANSWER_COUNT = quizQuestions.filter((q) => q.required).length;

type QuizQuestion = {
  key?: string;
  type: string;
  condition?: string;
  required: boolean;
  question: string;
  answers?: string[];
  points?: number[];
};

export function isApproved(quizState: QuizState) {
  return quizState.points >= REQUIRED_POINTS;
}

export function Quiz({ quizId = quizInitialState.id }: { quizId?: string }) {
  const isDevUser = useIsDevUser();
  const {
    values: [localQuizzes],
    setItem,
    store,
  } = useLocalforage<[QuizState[]]>([LOCALFORAGE_KEY_QUIZZES]);
  const router = useRouter();
  const quizzes = useMemo(() => localQuizzes ?? [], [localQuizzes]);
  const getActiveQuiz = useCallback(async () => {
    const quizzes = (await store?.getItem<QuizState[]>(LOCALFORAGE_KEY_QUIZZES)) || [quizInitialState];
    const quizStateUnparsed = quizzes.find((q) => q.id === quizId);
    return parseQuizState(quizStateUnparsed);
  }, [store, quizId]);
  const localQuizState = (quizzes || []).find((q) => q.id === quizId) ?? quizInitialState;

  const setQuizItem = useCallback(
    async (questionText: string, answerText: string) => {
      try {
        const quizState = await getActiveQuiz();
        const flatQuizQuestions = quizQuestions.reduce<QuizQuestion[]>((acc, curr) => {
          const { dependentQuestions, ...currentQuestion } = curr;

          const answerText = quizState.answers.find((a) => a.question === curr.question)?.text;

          acc.push(currentQuestion);

          if (dependentQuestions) {
            const dependentQuestion = dependentQuestions.find((d) => d.condition === answerText);

            dependentQuestion && acc.push(dependentQuestion);
          }

          return acc;
        }, []);
        const originalQuestion = flatQuizQuestions.find((q) => q.question === questionText);
        const hasAnswers = originalQuestion?.answers?.length;
        const answerIndex = hasAnswers ? originalQuestion?.answers?.findIndex((a) => a === answerText) ?? -1 : -1;
        const points = hasAnswers && answerIndex !== -1 ? originalQuestion?.points?.[answerIndex] ?? 0 : 0;
        const calculatedAnswer = answerSchema.parse({
          question: questionText,
          text: answerText,
          points,
          key: originalQuestion?.key,
        });
        const questionIndex = quizState.answers.findIndex((a) => a.question === questionText);

        if (questionIndex !== -1) {
          quizState.answers[questionIndex] = calculatedAnswer;
        } else {
          quizState.answers.push(calculatedAnswer);
        }

        const requiredCount = getRequiredCount(quizState);
        const answeredQuestions = quizState.answers.filter((a) => !!a.text).length;

        quizState.points = quizState.answers.reduce((acc, curr) => acc + curr.points, 0);
        quizState.isValid = answeredQuestions >= requiredCount && quizState.points > 0;

        const updatedQuizzes = quizzes.map((q) => (q.id === quizState.id ? quizState : q));
        await setItem(LOCALFORAGE_KEY_QUIZZES, updatedQuizzes);
      } catch (error) {
        console.error(error);
      }
    },
    [getActiveQuiz, quizzes, setItem]
  );
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const quizState = await getActiveQuiz();

      if (quizState.isValid) {
        quizState.submitted = new Date().toISOString();
        delete quizState.saved;

        const updatedQuizzes = quizzes.map((q) => (q.id === quizState.id ? quizState : q));
        const quizExists = updatedQuizzes.find((q) => q.id === quizState.id);

        if (!quizExists) {
          updatedQuizzes.push(quizState);
        }

        await setItem(LOCALFORAGE_KEY_QUIZZES, updatedQuizzes);

        router.push('/user/dashboard');
      } else {
        console.error(`quizState is not valid: ${JSON.stringify(quizState)}`);
      }
    },
    [getActiveQuiz, quizzes, router, setItem]
  );

  useEffect(() => {
    if (store) {
      (async () => {
        await setItem(LOCALFORAGE_KEY_QUIZ_ACTIVE, quizId);

        const quizzes = await store?.getItem<QuizState[]>(LOCALFORAGE_KEY_QUIZZES);
        const quizExists = quizzes?.find((q) => q.id === quizId);
        const firstQuiz = quizzes?.[0];

        if (!quizExists && firstQuiz) {
          const updatedQuizzes = [
            ...quizzes,
            { ...quizInitialState, answers: firstQuiz.answers.filter((a) => a.key !== 'terms_of_service') },
          ];

          await setItem(LOCALFORAGE_KEY_QUIZZES, updatedQuizzes);
        }
      })();
    }
  }, [quizId, store, setItem]);

  useEffect(() => {}, [localQuizState]);

  return (
    <Form.Root onSubmit={onSubmit}>
      <Box
        css={{
          gap: 6,
        }}
        layout='flexColumn'
      >
        {quizQuestions.map(
          ({
            dependentQuestions,
            key,
            type,
            question,
            answers = [],
            hint,
            points,
            label,
            placeholder,
            min,
            required,
          }) => {
            const existingAnswer = localQuizState.answers.find((a) => a.question === question);
            const isInvalid = (existingAnswer && existingAnswer.points < 0) || (required && !existingAnswer?.text);
            const isSuicideQuestion = key === 'suicide';
            const isDev = window.location.href.includes('localhost') || isDevUser;
            const [dependentQuestion] =
              dependentQuestions?.filter(({ condition }) => existingAnswer?.text === condition) || [];

            if (isDev && key === 'state' && !answers.includes('Testizona')) {
              answers.push('Testizona');
            }

            return (
              <Box css={{ position: 'relative' }} key={question}>
                <Box css={{ position: 'relative', zIndex: 1, gap: 3 }} layout='flexColumn'>
                  <Box layout='flexColumn'>
                    <Box layout='flexRow'>
                      <Typography css={{ flex: 1 }} type='subtitle2'>
                        {question}
                      </Typography>
                      {required && (
                        <Icon
                          css={{ color: !existingAnswer ? 'rgb(200, 200, 200)' : isInvalid ? 'red' : 'green' }}
                          name='check'
                          size='2xl'
                        />
                      )}
                    </Box>
                    {isSuicideQuestion && (
                      <Typography type='caption'>
                        Suicide hotline:{' '}
                        <a href='https://988lifeline.org/' style={{ textDecoration: 'underline' }}>
                          https://988lifeline.org/
                        </a>
                      </Typography>
                    )}
                    <Typography type='caption'>{hint}</Typography>
                  </Box>
                  <Box css={{ gap: 3, flexWrap: 'wrap' }} layout='flexRow'>
                    {answers.map((answer) => {
                      const isSuicideAnswer = isSuicideQuestion && answer === 'Yes';
                      const isSelected = existingAnswer?.text === answer;

                      return (
                        <Box key={question + answer}>
                          <Button
                            css={{ minWidth: '5rem' }}
                            onClick={(e) => {
                              e.preventDefault();

                              if (isSuicideAnswer) {
                                alert('Please call 988 for the suicide hotline.');
                              }
                              setQuizItem(question, answer);
                            }}
                            size='sm'
                            variant={isSelected ? 'filled' : 'outlined'}
                          >
                            {answer}
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                  {(type === 'text' || type === 'number') && (
                    <Box>
                      <Form.ControlledInput
                        className={css({ maxWidth: '100%', background: 'transparent', '&:focus': { outline: 'none' } })}
                        defaultValue={existingAnswer?.text}
                        label={label}
                        min={min}
                        name={question}
                        onChange={(e) => {
                          setQuizItem(question, e.target.value);
                        }}
                        placeholder={placeholder}
                        type={type}
                      />
                    </Box>
                  )}

                  {!!dependentQuestion && (
                    <DependentQuestion
                      dependentQuestion={dependentQuestion}
                      existingAnswer={localQuizState.answers.find((a) => a.question === dependentQuestion.question)}
                      setQuizItem={setQuizItem}
                    />
                  )}
                </Box>
              </Box>
            );
          }
        )}
      </Box>
      <button id='quiz-submit' tabIndex={-1} type='submit' />
    </Form.Root>
  );
}

type DependentQuestionProps = {
  dependentQuestion: {
    key: string;
    type: string;
    condition: string;
    required: boolean;
    question: string;
    answers: string[];
  };
  existingAnswer?: {
    text: string;
    points: number;
    key: string | null;
    question: string;
  };
  setQuizItem: any;
};

function DependentQuestion({ dependentQuestion, existingAnswer, setQuizItem }: DependentQuestionProps) {
  const isInvalid =
    (existingAnswer && existingAnswer.points < 0) || (dependentQuestion.required && !existingAnswer?.text);

  return (
    <Box css={{ position: 'relative', zIndex: 1, gap: 3, paddingTop: 3 }} layout='flexColumn'>
      <Box layout='flexRow'>
        <Typography css={{ flex: 1 }} type='subtitle2'>
          {dependentQuestion.question}
        </Typography>
        {dependentQuestion.required && (
          <Icon
            css={{ color: !existingAnswer ? 'rgb(200, 200, 200)' : isInvalid ? 'red' : 'green' }}
            name='check'
            size='2xl'
          />
        )}
      </Box>

      <Box css={{ gap: 3, flexWrap: 'wrap' }} layout='flexRow'>
        {dependentQuestion.answers.map((answer) => {
          const isSelected = existingAnswer?.text === answer;

          return (
            <Box key={dependentQuestion.key + answer}>
              <Button
                css={{ minWidth: '5rem' }}
                onClick={(e) => {
                  e.preventDefault();

                  setQuizItem(dependentQuestion.question, answer);
                }}
                size='sm'
                variant={isSelected ? 'filled' : 'outlined'}
              >
                {answer}
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function getRequiredCount(quizState: QuizState) {
  const requiredTopLevel = quizQuestions.filter((q) => q.required).length;
  const requiredDependent = quizQuestions.filter((q) => {
    const existingAnswer = quizState.answers.find((a) => a.question === q.question)?.text;
    const dependentQuestion = q.dependentQuestions?.find((d) => d.condition === existingAnswer);

    return dependentQuestion && dependentQuestion.required;
  }).length;

  return requiredTopLevel + requiredDependent;
}
