import { QuizState, quizStateSchema } from '../../providers/schemas';

import { customAlphabet } from 'nanoid';
import quizJson from './quiz.json';

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 6);
export function createNanoId() {
  return nanoid();
}

export const quizInitialState: QuizState = {
  id: createNanoId(),
  answers: [],
  isValid: false,
  created: new Date().toISOString(),
  points: 0,
  approvedAt: '',
  approvedBy: '',
  providerPaid: false,
  promotionCode: null,
  claimedAt: '',
  claimedBy: '',
  assignedTo: '',
  assignedAt: '',
};

export function parseQuizState(quizStateUnparsed: unknown): QuizState {
  const quizStateParsed = quizStateSchema.safeParse(quizStateUnparsed);
  const quizState = quizStateParsed.success ? quizStateParsed.data : quizInitialState;

  return quizState;
}

type AnswerKey =
  | 'pet_name'
  | 'pet_breed'
  | 'pet_pounds'
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'phone_number'
  | 'preferred_contact_method'
  | 'state'
  | 'city'
  | 'terms_of_service';
type QuizAnswers = Record<AnswerKey, string>;

export function getQuizAnswers(quiz: QuizState) {
  const answers = quiz.answers.reduce<QuizAnswers>((acc, answer) => {
    if (answer.key) {
      acc[answer.key as AnswerKey] = answer.text;
    }

    return acc;
  }, {} as QuizAnswers);
  const diagnoses = quiz.answers.reduce<Record<string, number>>((acc, answer) => {
    const quizJsonAnswer = quizJson.find((q) => q.question === answer.question);
    if (quizJsonAnswer && quizJsonAnswer.diagnosis.length) {
      if (!acc[quizJsonAnswer.diagnosis]) {
        acc[quizJsonAnswer.diagnosis] = 0;
      }

      acc[quizJsonAnswer.diagnosis] += answer.points;
    }

    return acc;
  }, {});
  const formattedAnswers = {
    diagnoses,
    firstName: answers.first_name,
    lastName: answers.last_name,
    fullName: `${answers.first_name} ${answers.last_name}`,
    petName: answers.pet_name,
    petBreed: answers.pet_breed,
    petPounds: answers.pet_pounds,
    email: answers.email,
    phoneNumber: answers.phone_number,
    preferredContactMethod: answers.preferred_contact_method,
    state: answers.state,
    city: answers.city,
    termsOfService: answers.terms_of_service,
  };

  return formattedAnswers;
}
