'use client';

import { Box } from 'ui/components/box';
import { Typography } from 'ui/components/typography';
import { useBreakpoints } from 'ui/hooks/use-breakpoints';
import { useElWidth } from 'ui/hooks/use-el-width';
import { useKeydown } from 'ui/hooks/use-keydown';
import { useLocalforage } from 'ui/hooks/use-localforage';
import { useEffect, useRef } from 'react';

const ACTIVE_KEY = 'is-dev-active';
const USER_KEY = 'is-dev-user';

export function Dev() {
  const ref = useRef<HTMLDivElement>(null);
  const { active } = useBreakpoints();
  const {
    values: [show],
    setItem,
    store,
  } = useLocalforage<[boolean]>([ACTIVE_KEY]);

  // eslint-disable-next-line no-undef
  const isActive = typeof window !== 'undefined'; // && window.location.host.includes('localhost');
  const width = useElWidth({ isActive, el: ref.current });

  useKeydown(
    {
      isActive,
      callback: (e) => {
        const event = e as KeyboardEvent;

        if (event.key === '?') {
          setItem(ACTIVE_KEY, !show);
        }
      },
    },
    [show]
  );

  useEffect(() => {
    if (store) {
      const params = new URLSearchParams(window.location.search);

      if (params.has('dev')) {
        store.setItem(USER_KEY, true);
      } else if (params.has('prod')) {
        store.setItem(USER_KEY, false);
      }
    }
  }, [store]);

  return show ? (
    <Box
      css={{
        position: 'fixed',
        right: 0,
        left: 0,
        bottom: 0,

        gap: 6,

        padding: 4,
        backgroundColor: 'rgba(240, 240, 240, 0.75)',
      }}
      layout='centeredRow'
      ref={ref}
    >
      <Typography type='caption'>{active}</Typography>
      <Typography type='caption'>{width}px</Typography>
    </Box>
  ) : null;
}

export function useIsDevUser() {
  const {
    values: [isDevUser],
  } = useLocalforage<[boolean]>([USER_KEY]);

  return isDevUser;
}
