import { useEffect, useState } from 'react';

type UseElWidthArgs = {
  isActive: boolean;
  el?: HTMLElement | null;
};

export function useElWidth({ isActive, el }: UseElWidthArgs) {
  const [divWidth, setDivWidth] = useState<number>(0);

  useEffect(() => {
    if (el && isActive) {
      const observer = new ResizeObserver(([entry]) => {
        entry && setDivWidth(entry.contentRect.width);
      });

      observer.observe(el);

      return () => {
        observer.unobserve(el);
      };
    }
  }, [el, isActive]);

  return divWidth;
}
